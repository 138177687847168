<template>
	<div class="page_bl">
		<el-form class="form_bl" label-width="100px">
			
			<div class="big_tit" style="margin:0">选择线路</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="线路列表" style="width:1000px">
					<el-select class="el_inner_width" @change="tord_real_time_bl_change" placeholder="请选择" v-model="tord_real_time_bl.rel" clearable>
						<el-option v-for="(item,index) in tord_real_time_bl.data" :key="index" :label="item.info_text" :value="index"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物/距离" style="width:1000px">
					<el-input class="el_inner_width" v-model="tord_real_time_bl.cargo_info_and_distance" disabled clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="装货地详情" style="width:1000px">
					<el-input class="el_inner_width" v-model="tord_real_time_bl.case_addr.total_text" disabled clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="卸货地详情" style="width:1000px">
					<el-input class="el_inner_width" v-model="tord_real_time_bl.aim_addr.total_text" disabled clearable></el-input>
				</el-form-item>
			</div>
			

			<div class="big_tit">重量体积</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物重量(吨)" style="width:388px">
					<el-input class="el_inner_width" type="number" v-model="cargo_weight.num" :placeholder="cargo_weight.is_need_weight_text" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货物体积(方)" style="width:588px">
					<el-input class="el_inner_width" type="number" v-model="cargo_volume.num" :placeholder="cargo_volume.is_need_volume_text" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">装卸货地联系人</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="装货联系人" style="width:388px">
					<el-input class="el_inner_width" v-model="form.case_link_man" placeholder="装货地联系人" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="装货联系电话" style="width:588px">
					<el-input class="el_inner_width" v-model="form.case_link_tel" type="number" placeholder="装货地联系电话" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="卸货联系人" style="width:388px">
					<el-input class="el_inner_width" v-model="form.aim_link_man" placeholder="卸货地联系人" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="卸货联系电话" style="width:588px">
					<el-input class="el_inner_width" v-model="form.aim_link_tel" type="number" placeholder="卸货地联系电话" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">发车日期</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="发车日期" style="width:488px">
					<el-date-picker 
						v-model="form.start_time"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="限定当日" style="width:488px">
					<el-select class="el_inner_width" v-model="form.start_time_is_check">
						<el-option label="是" value="1"></el-option>
						<el-option label="否" value="2"></el-option>
					</el-select>
				</el-form-item>
			</div>


			<div class="big_tit">指定收款人</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="收款人电话" style="width:388px">
					<el-input class="el_inner_width" type="number" v-model="truck_owner.tel" placeholder="不填表示不指定" clearable>
						<el-button @click="truck_owner_ser" type="success" slot="append">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="收款人信息" style="width:588px">
					<el-input class="el_inner_width" v-model="truck_owner.name" :disabled="true" clearable>
						<el-button @click="truck_owner_clear" type="success" slot="append">清空</el-button>
					</el-input>
				</el-form-item>
			</div>


			<div class="big_tit">其他信息 (运费信息说明: 如果不填运费则只能进行招投标, 如果填写运费可以直接抢单也可以指定也可以招投标)</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="运费(元)" style="width:388px">
					<el-input class="el_inner_width" type="number" v-model="form.freight_total" placeholder="请输入运费信息" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="车型要求" style="width:588px">
					<el-cascader class="el_inner_width" v-model="form.need_truck_type" placeholder="非必填" :options="truck_type" filterable></el-cascader>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="上游单号" style="width:388px">
					<el-input class="el_inner_width" v-model="form.out_trade_num" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="组别编号" style="width:588px">
					<el-input class="el_inner_width" v-model="form.group1_num" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				 <el-form-item class="el_form_item" label="项目列表" style="width:388px" v-if="company_item_list.length > 0">
					<el-select   class="el_inner_width" v-model="form.item_id">
						<el-option v-for="(item,index) in company_item_list" :key="index" :label="item.item_name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="运单备注" style="width:588px">
					<el-input v-model="form.mark" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>

			</div>
			<div class="big_tit">收货方信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="收货方名称" style="width:388px">
					<el-input class="el_inner_width" v-model="form.consignee" placeholder="收货方名称(公司名称)" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="信用代码" style="width:388px">
					<el-input class="el_inner_width" v-model="form.consigneeid" placeholder="统一社会信用代码" clearable></el-input>
				</el-form-item>
			</div>
			<div class="big_tit">销售方信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="销售方名称" style="width:388px">
					<el-input class="el_inner_width" v-model="form.seller_name" placeholder="销售方名称(公司名称)" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="信用代码" style="width:388px">
					<el-input class="el_inner_width" v-model="form.seller_code" placeholder="销售方统一社会信用代码" clearable></el-input>
				</el-form-item>
			</div>
		</el-form>


		<div class="bottom_btns">
			<el-button type="primary" @click="sub">提交订单</el-button>
			<el-button type="danger" @click="clear">清空数据</el-button>
		</div>
	</div>
</template>
<script>
	import {regionData,CodeToText,TextToCode} from 'element-china-area-data'
	import truck_type_handle from '@/my/_truck_type_handle.js'
	import {mapState} from 'vuex'
	export default {
		data() {
			return {

				//表单
				form: {
					case_link_man:'',//装货地联系人
					case_link_tel:'',//装货地联系电话
					aim_link_man:'',//卸货地联系人
					aim_link_tel:'',//卸货地联系电话
					start_time:null,//发车日期
					start_time_is_check:'1',//发车日期是否限定当日
					freight_total:'',//运费
					need_truck_type:null,//车型要求
					out_trade_num:'',//上游运单编号
					group1_num:'',//组编号1(用户自定义打组)
					mark:'',//备注
					item_id:'',//项目id
					consignee:'',//收货方

					consigneeid:'',//收货方信用代码
					seller_name:'',//销售方

					seller_code:'',//销售方社会信用代码
				},

				//货主线路列表
				tord_real_time_bl:{
					rel:'',//选择结果
					data:[],//联动主数据
					bl_num:'',//线路编号
					cargo_name:"",//货物名称
					cargo_type:"",//货物名称
					settlement_type:'',//结算方式(1:按吨数,2:按方数,3:按趟)
					is_need_return:'',//是否回程(1:需要,2:不需要)
					distance:'',//运输距离(单位:公里)
					cargo_info_and_distance:'',//货物信息和运输距离
					case_addr:{
						prov:'',
						city:'',
						county:'',
						prov_code:'',
						city_code:'',
						county_code:'',
						addr:'',
						lat:0,
						lng:0,
						total_text:''
					},
					aim_addr:{
						prov:'',
						city:'',
						county:'',
						prov_code:'',
						city_code:'',
						county_code:'',
						addr:'',
						lat:0,
						lng:0,
						total_text:''
					}
				},

				//货物重量
				cargo_weight:{
					num:'',//数值
					is_need_weight:'',//是否必填
					is_need_weight_text:'',//是否必填的placeholder
				},

				//货物体积
				cargo_volume:{
					num:'',//数值
					is_need_volume:'',//是否必填
					is_need_volume_text:'',//是否必填的placeholder
				},

				//城市联动数据
				cities:regionData,
				
				//车型
				truck_type:[],

				//承运人
				truck_owner:{
					tel:'',//承运人电话
					name:'',//承运人姓名
					user_num:'',//承运人用户编号
					truck_plate_num:'',//车牌号
				},
				company_item_list:'',//项目列表下拉框
			}
		},
		computed:{
			...mapState(['user_info'])
		},
		created() {

			//获取创建参数
			this.get_creat_para();

			//获取参数
			this.get_tord_real_time_bl();

			//查询项目列表
			this.get_company_item_list()
		},
		methods: {

			//提交
			sub(){

				let post_data={
					mod:'truck_tord_real_time',
					ctr:'cargo_add_by_sub_user',
					settlement_type:this.tord_real_time_bl.settlement_type,
					is_need_return:this.tord_real_time_bl.is_need_return,
					bl_num:this.tord_real_time_bl.bl_num,
					cargo_name:this.tord_real_time_bl.cargo_name,
					cargo_type:this.tord_real_time_bl.cargo_type,
					case_prov:this.tord_real_time_bl.case_addr.prov,
					case_prov_code:this.tord_real_time_bl.case_addr.prov_code,
					case_city:this.tord_real_time_bl.case_addr.city,
					case_city_code:this.tord_real_time_bl.case_addr.city_code,
					case_county:this.tord_real_time_bl.case_addr.county,
					case_county_code:this.tord_real_time_bl.case_addr.county_code,
					case_lat:this.tord_real_time_bl.case_addr.lat,
					case_lng:this.tord_real_time_bl.case_addr.lng,
					case_addr:this.tord_real_time_bl.case_addr.addr,
					aim_prov:this.tord_real_time_bl.aim_addr.prov,
					aim_prov_code:this.tord_real_time_bl.aim_addr.prov_code,
					aim_city:this.tord_real_time_bl.aim_addr.city,
					aim_city_code:this.tord_real_time_bl.aim_addr.city_code,
					aim_county:this.tord_real_time_bl.aim_addr.county,
					aim_county_code:this.tord_real_time_bl.aim_addr.county_code,
					aim_lat:this.tord_real_time_bl.aim_addr.lat,
					aim_lng:this.tord_real_time_bl.aim_addr.lng,
					aim_addr:this.tord_real_time_bl.aim_addr.addr,
					distance:this.tord_real_time_bl.distance,
					item_id:this.form.item_id,
					consigneeid:this.form.consigneeid,
					consignee:this.form.consignee,
					seller_code:this.form.seller_code,
					seller_name:this.form.seller_name
				};

				//货物重量
				if(this.cargo_weight.is_need_weight==1){//必填
					if(!(this.$my.check.is_num(this.cargo_weight.num)&&this.cargo_weight.num>0)){
						this.$my.other.msg({
							type:"warning",
							str:'此路线必填吨数, 请输入货物吨数'
						});
						return;
					}else post_data.cargo_weight=this.cargo_weight.num
				}else if(this.cargo_weight.is_need_weight==2){//非必填
					if(this.$my.check.is_num(this.cargo_weight.num)&&this.cargo_weight.num>0){
						post_data.cargo_weight=this.cargo_weight.num
					}
				}else{
					this.$my.other.msg({
						type:"warning",
						str:'请选择路线'
					});
					return;
				}

				//货物体积
				if(this.cargo_volume.is_need_volume==1){//必填
					if(!(this.$my.check.is_num(this.cargo_volume.num)&&this.cargo_volume.num>0)){
						this.$my.other.msg({
							type:"warning",
							str:'此路线必填体积, 请输入货物体积'
						});
						return;
					}else post_data.cargo_volume=this.cargo_volume.num
				}else if(this.cargo_volume.is_need_volume==2){//非必填
					if(this.$my.check.is_num(this.cargo_volume.num)&&this.cargo_volume.num>0){
						post_data.cargo_volume=this.cargo_volume.num
					}
				}else{
					this.$my.other.msg({
						type:"warning",
						str:'请选择路线'
					});
					return;
				}

				if(this.truck_owner.user_num){//收款人和运费

					//如果指定了收款人,必须有运费
					if(!(this.$my.check.is_num(this.form.freight_total)&&this.form.freight_total>0)){//运费
						this.$my.other.msg({
							type:"warning",
							str:'如果指定了收款人,必须填写运费'
						});
						return;
					}

					post_data.freight_total=this.form.freight_total
					post_data.truck_owner_num=this.truck_owner.user_num
					post_data.truck_plate_num=this.truck_owner.truck_plate_num
				}else{

					if(this.$my.check.is_num(this.form.freight_total)&&this.form.freight_total>0){//运费
						post_data.freight_total=this.form.freight_total
					}
				}

				//装货地联系人/联系电话
				if(this.form.case_link_tel && !this.$my.check.is_tel(this.form.case_link_tel)){
					this.$my.other.msg({
						type:'warning',
						str:"装货地联系电话格式有误"
					});
					return;
				}else{
					post_data.case_link_tel=this.form.case_link_tel
					post_data.case_link_man=this.form.case_link_man
				}

				//发车日期
				// if(this.form.case_time){
				// 	let case_time_start=this.form.case_time[0].getTime();
				// 	let case_time_end=this.form.case_time[1].getTime();
				// 	let now_time=new Date((new Date().setHours(0, 0, 0, 0))).getTime()
				// 	if(case_time_start<now_time){
				// 		this.$my.other.msg({
				// 			type:"warning",
				// 			str:'装货日期起点不能早于当前时间'
				// 		});
				// 		return;
				// 	}
				// 	post_data.case_time_start=(case_time_start/1000).toFixed(0)
				// 	post_data.case_time_end=(case_time_end/1000).toFixed(0)
				// }
				if(this.form.start_time){
					let start_time_1=this.form.start_time[0].getTime();
					let start_time_2=this.form.start_time[1].getTime();
					let now_time=new Date((new Date().setHours(0, 0, 0, 0))).getTime()
					// if(start_time_1<now_time){
					// 	this.$my.other.msg({
					// 		type:"warning",
					// 		str:'发车日期起点不能早于当前时间'
					// 	});
					// 	return;
					// }
					post_data.start_time_1=(start_time_1/1000).toFixed(0)
					post_data.start_time_2=(start_time_2/1000).toFixed(0)
				}

				//发车日期是否强制校验
				if(['1','2'].indexOf(this.form.start_time_is_check)==-1){
					this.$my.other.msg({
						type:"warning",
						str:'请确定发车日期是否限定当日'
					});
					return;
				}else{
					post_data.start_time_is_check=this.form.start_time_is_check
				}

				//卸货地联系电话
				if(this.form.aim_link_tel && !this.$my.check.is_tel(this.form.aim_link_tel)){
					this.$my.other.msg({
						type:'warning',
						str:"卸货地联系电话格式有误"
					});
					return;
				}else{
					post_data.aim_link_tel=this.form.aim_link_tel
					post_data.aim_link_man=this.form.aim_link_man
				}

				// //卸货日期
				// if(this.form.aim_time){
				// 	let aim_time_start=this.form.aim_time[0].getTime();
				// 	let aim_time_end=this.form.aim_time[1].getTime();
				// 	let now_time=(new Date()).getTime()
				// 	if(post_data.case_time_end&&aim_time_start<post_data.case_time_end){
				// 		this.$my.other.msg({
				// 			type:"warning",
				// 			str:'卸货日期起点不能早于装货日期'
				// 		});
				// 		return;
				// 	}else if(!post_data.case_time_end&&aim_time_start<now_time){
				// 		this.$my.other.msg({
				// 			type:"warning",
				// 			str:'卸货日期起点不能早于当前时间'
				// 		});
				// 		return;
				// 	}
				// 	post_data.aim_time_start=(aim_time_start/1000).toFixed(0)
				// 	post_data.aim_time_end=(aim_time_end/1000).toFixed(0)
				// }
				
				//租用货车类型
				if(this.form.need_truck_type){
					post_data.need_truck_type=this.form.need_truck_type[0]
					post_data.need_truck_type2=this.form.need_truck_type[1]
				}

				if(this.form.out_trade_num.trim()){//上游运单编号
					post_data.out_trade_num=this.form.out_trade_num.trim()
				}

				if(this.form.group1_num.trim()){//组编号1(用户自定义打组)
					post_data.group1_num=this.form.group1_num.trim()
				}

				if(this.form.mark.trim()){//备注
					post_data.mark=this.form.mark.trim()
				}

				//提交数据
				this.$my.net.req({
					data:post_data,
					callback:(data)=>{

						//如果指定了收款人, 前往'待接单'
						if(post_data.truck_owner_num){
						
							//添加成功
							this.$my.other.confirm({
								content:"发货成功,是否前往待接单列表?",
								confirm:()=>{

									this.$router.push({
										path:'/pages/sub_user/main_user_sys/tord/tord_deal'
									});
								}
							});

						//如果没有指定收款人, 前往'货源列表'
						}else{

							//添加成功
							this.$my.other.confirm({
								content:"发货成功,是否前往货源列表?",
								confirm:()=>{

									this.$router.push({
										path:'/pages/sub_user/main_user_sys/cargo/cargo_list'
									});
								}
							});
						}
					}
				});
			},

			//查找司机
			truck_owner_ser(){
				
				//格式判断
				if(!this.$my.check.is_tel(this.truck_owner.tel)){
					this.$my.other.msg({
						type:"warning",
						str:'司机电话号码格式有误'
					});
					return;
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_owner_info',
						truck_owner_tel:this.truck_owner.tel
					},
					callback:(data)=>{

						if(data.name=='未实名'){
							this.$my.other.msg({
								type:"warning",
								str:'此用户未完成实名认证'
							});
							return;
						}

						let user_info=this.user_info
						if(user_info.user_num==data.user_num){
							this.$my.other.msg({
								type:"warning",
								str:'不能指定自己'
							});
							return;
						}
						this.truck_owner.truck_plate_num=data.truck_info.truck_plate_num
						this.truck_owner.user_num=data.user_num
						this.truck_owner.name=data.name
					}
				});
			},
			//项目列表
			get_company_item_list(){
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'app_user',
						ctr:'company_item_list',
					},callback:(res)=>{
						if(res.code != 0){
							this.company_item_list = []
							return
						}
						this.company_item_list = res.msg.list
						
					}
				})
			},
			//清空司机信息
			truck_owner_clear(){
				this.truck_owner.tel=''
				this.truck_owner.name=''
				this.truck_owner.user_num=''
				this.truck_owner.truck_plate_num=''
			},

			//清空本页数据
			clear(){

				this.$my.other.confirm({
					content:"点击确定清空本页数据",
					confirm:()=>{

						//清空表单内数据
						this.form.case_link_man=""
						this.form.case_link_tel=""
						this.form.aim_link_man=""
						this.form.aim_link_tel=""
						this.form.start_time=null
						this.form.start_time_is_check=2
						this.form.freight_total=''
						this.form.need_truck_type=null
						this.form.out_trade_num=''
						this.form.group1_num=''
						this.form.mark=''

						this.cargo_weight.num=''
						this.cargo_weight.is_need_weight=''
						this.cargo_weight.is_need_weight_text=''

						this.cargo_volume.num=''
						this.cargo_volume.is_need_volume=''
						this.cargo_volume.is_need_volume_text=''

						this.truck_owner.tel=''
						this.truck_owner.name=''
						this.truck_owner.user_num=''

						this.form.consignee='',
						this.form.consigneeid='',
						this.form.seller_code='',
						this.form.seller_name='',
						//清空选择结果 
						this.tord_real_time_bl.rel=''
						this.tord_real_time_bl.bl_num=''
						this.tord_real_time_bl.cargo_name=''
						this.tord_real_time_bl.cargo_type=''
						this.tord_real_time_bl.settlement_type=''
						this.tord_real_time_bl.is_need_return=''
						this.tord_real_time_bl.distance=''
						this.tord_real_time_bl.cargo_info_and_distance=''
						this.tord_real_time_bl.case_addr={
							prov:'',
							city:'',
							county:'',
							prov_code:'',
							city_code:'',
							county_code:'',
							addr:'',
							lat:0,
							lng:0,
							total_text:''
						}

						this.tord_real_time_bl.aim_addr={
							prov:'',
							city:'',
							county:'',
							prov_code:'',
							city_code:'',
							county_code:'',
							addr:'',
							lat:0,
							lng:0,
							total_text:''
						}
					}
				});
			},

			//客户线路选择
			tord_real_time_bl_change(){

				//取出线路详情
				let bl_info=this.tord_real_time_bl.data[this.tord_real_time_bl.rel]

				//写入线路编号
				this.tord_real_time_bl.bl_num=bl_info.bl_num

				//写入货物名称
				this.tord_real_time_bl.cargo_name=bl_info.cargo_name

				//写入货物类型
				this.tord_real_time_bl.cargo_type=bl_info.cargo_type

				//写入货物信息和运输距离
				this.tord_real_time_bl.cargo_info_and_distance=bl_info.cargo_name+"("+bl_info.cargo_type+") / "+bl_info.distance+"公里"

				//写入发货地
				this.tord_real_time_bl.case_addr.prov=bl_info.case_prov
				this.tord_real_time_bl.case_addr.city=bl_info.case_city
				this.tord_real_time_bl.case_addr.county=bl_info.case_county
				this.tord_real_time_bl.case_addr.addr=bl_info.case_other_obj.addr_info
				this.tord_real_time_bl.case_addr.lat=bl_info.case_lat
				this.tord_real_time_bl.case_addr.lng=bl_info.case_lng
				this.tord_real_time_bl.case_addr.total_text=bl_info.case_prov+' / '+bl_info.case_city+' / '+bl_info.case_county+"  / "+bl_info.case_other_obj.addr_info

				//写入发货地县区区划
				this.tord_real_time_bl.case_addr.prov_code=bl_info.case_other_obj.prov_code
				this.tord_real_time_bl.case_addr.city_code=bl_info.case_other_obj.city_code
				this.tord_real_time_bl.case_addr.county_code=bl_info.case_other_obj.county_code

				//写入装货地联系人/电话
				this.form.case_link_man=bl_info.case_link_man
				this.form.case_link_tel=bl_info.case_link_tel

				//写入卸货地
				this.tord_real_time_bl.aim_addr.prov=bl_info.aim_prov
				this.tord_real_time_bl.aim_addr.city=bl_info.aim_city
				this.tord_real_time_bl.aim_addr.county=bl_info.aim_county
				this.tord_real_time_bl.aim_addr.addr=bl_info.aim_other_obj.addr_info
				this.tord_real_time_bl.aim_addr.lat=bl_info.aim_lat
				this.tord_real_time_bl.aim_addr.lng=bl_info.aim_lng
				this.tord_real_time_bl.aim_addr.total_text=bl_info.aim_prov+' / '+bl_info.aim_city+' / '+bl_info.aim_county+"   / "+bl_info.aim_other_obj.addr_info

				//写入发货地县区区划
				this.tord_real_time_bl.aim_addr.prov_code=bl_info.aim_other_obj.prov_code
				this.tord_real_time_bl.aim_addr.city_code=bl_info.aim_other_obj.city_code
				this.tord_real_time_bl.aim_addr.county_code=bl_info.aim_other_obj.county_code

				//写入卸货地联系人/电话
				this.form.aim_link_man=bl_info.aim_link_man
				this.form.aim_link_tel=bl_info.aim_link_tel

				//结算方式
				this.tord_real_time_bl.settlement_type=bl_info.settlement_type

				//是否是双边
				this.tord_real_time_bl.is_need_return=bl_info.is_need_return

				//运输距离
				this.tord_real_time_bl.distance=bl_info.distance

				//运费
				this.form.freight_total=bl_info.freight_total

				//备注
				this.form.mark=bl_info.bl_name?bl_info.bl_name:''

				//是否必填重量
				this.cargo_weight.is_need_weight=bl_info.is_need_weight
				switch(bl_info.is_need_weight){
					case '1':this.cargo_weight.is_need_weight_text="必填, 请输入";break;
					case '2':this.cargo_weight.is_need_weight_text="非必填";break;
				}

				//是否必填体积
				this.cargo_volume.is_need_volume=bl_info.is_need_volume
				switch(bl_info.is_need_volume){
					case '1':this.cargo_volume.is_need_volume_text="必填, 请输入";break;
					case '2':this.cargo_volume.is_need_volume_text="非必填";break;
				}
			},

			//获取创建参数
			get_creat_para(){

				//尝试从缓存中取出
				let creat_para=this.$my.cache.get('cargo_creat_page_creat_para');

				//如果取出成功
				if(creat_para){
					this.truck_type=creat_para.truck_type
					return
				}

				//如果取出失败,去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'cargo_creat_para'
					},
					callback:(data)=>{
						
						//格式化车型数据
						let truck_type=truck_type_handle.for_el_cascader(data.truck_type);
						
						//缓存之
						this.$my.cache.set({
							cargo_creat_page_creat_para:{
								truck_type:truck_type,
								cargo_type:data.cargo_type
							}
						});

						//置入
						this.truck_type=truck_type
					}
				})
			},
			
			//获取货主线路
			get_tord_real_time_bl(){

				//去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_real_time_bl_list_by_sub_user'
					},
					callback:(data)=>{

						//遍历
						for(var item of data.list){

							//结算方式
							var settlement_type_text=""
							switch(item.is_need_return){
								case '1':settlement_type_text="按吨数结算";break;
								case '2':settlement_type_text="按方数结算";break;
								case '3':settlement_type_text="按趟结算";break;
							}

							//是否是双边
							var is_need_return_text=""
							switch(item.is_need_return){
								case '1':is_need_return_text="双边";break;
								case '2':is_need_return_text="单边";break;
							}

							//装货地其他数据
							item.case_other_obj=JSON.parse(item.case_other);

							//卸货地其他数据
							item.aim_other_obj=JSON.parse(item.aim_other);

							//汇集路线数据
							item.info_text=item.case_prov+'/'+item.case_city+'/'+item.case_county+' ---- '+item.aim_prov+'/'+item.aim_city+'/'+item.aim_county+' ('+is_need_return_text+') ('+settlement_type_text+')'+'('+item.bl_name+')'
						}

						//置入路线数据
						this.tord_real_time_bl.data=data.list
					}
				})
			}
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.bottom_btns{
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: 100%;
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form_bl{
		height: calc(100% - 91px);
		padding: 10px 0;
		overflow-y:auto;
	}

	.page_bl{
		position:relative;
		height:100%;
	}
	
</style>