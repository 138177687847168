<template>
	<div class="page">
		
		<!-- 发布方式切换 -->
		<div class="type_change">
			<el-radio-group v-model="creat_type">
				<el-radio-button label="快捷发货"></el-radio-button>
				<el-radio-button label="直接发货"></el-radio-button>
			</el-radio-group>
		</div>

		<!-- 表单 -->
		<div class="form">

			<!-- 快捷发货 -->
			<ByBl :style="{display:creat_type=='快捷发货'?'block':'none'}"></ByBl>

			<!-- 直接发货 -->
			<ByOrdinary :style="{display:creat_type=='直接发货'?'block':'none'}"></ByOrdinary>

		</div>
	</div>
</template>
<script>
	import ByBl from './cargo_creat_by_bl.vue'
	import ByOrdinary from './cargo_creat_by_ordinary.vue'
	export default {
		components:{
			ByBl,
			ByOrdinary
		},
		data() {
			return {

				// creat_type:'直接发货'
				creat_type:'快捷发货'
			}
		},
		methods: {

		}
	}
</script>
<style lang="scss" scoped>

	.form{
		height: calc(100% - 71px);
		padding:0 15px;
	}

	.type_change{
		text-align: center;
		padding: 15px;
		border-bottom: 1px solid #ccc;
	}

	.page{
		background-color: #fff;
		border-radius: 10px;
		height: 100%;
		width: 1040px;
		overflow: auto;
		margin: auto;
	}

</style>